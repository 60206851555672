@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; background: none; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input { appearance: none; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

select:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

a { text-decoration: none; }

a, button { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.icon-arrow-left { width: 1.38em; height: 1em; fill: #FFF; }

.icon-arrow-next { width: 0.57em; height: 1em; fill: #9F9FA9; }

.icon-arrow-prev { width: 0.57em; height: 1em; fill: #9F9FA9; }

.icon-arrow-right { width: 1.38em; height: 1em; fill: #fff; }

.icon-arrows { width: 0.67em; height: 1em; fill: #111029; }

.icon-dribbble { width: 1em; height: 1em; fill: #111029; }

.icon-email { width: 1.2em; height: 1em; fill: #4C40F7; }

.icon-instagram { width: 1em; height: 1em; fill: #9F9FA9; }

.icon-message { width: 1em; height: 1em; fill: #111029; }

.icon-phone { width: 0.94em; height: 1em; fill: #4C40F7; }

.icon-place { width: 1em; height: 1em; fill: #4C40F7; }

.icon-profile { width: 0.94em; height: 1em; fill: #111029; }

.icon-send { width: 1em; height: 1em; fill: #4C40F7; }

/** Owl Carousel v2.3.4 Copyright 2013-2018 David Deutsch Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE */
/* Owl Carousel - Core */
.owl-carousel { display: none; width: 100%; -webkit-tap-highlight-color: transparent; /* position relative and z-index fix webkit rendering fonts issue */ position: relative; z-index: 1; }

.owl-carousel .owl-stage { position: relative; -ms-touch-action: pan-Y; touch-action: manipulation; -moz-backface-visibility: hidden; /* fix firefox animation glitch */ }

.owl-carousel .owl-stage:after { content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0; }

.owl-carousel .owl-stage-outer { position: relative; overflow: hidden; /* fix for flashing background */ -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper, .owl-carousel .owl-item { -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; -ms-backface-visibility: hidden; -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item { position: relative; min-height: 1px; float: left; -webkit-backface-visibility: hidden; -webkit-tap-highlight-color: transparent; -webkit-touch-callout: none; }

.owl-carousel .owl-item img { display: block; width: 100%; }

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled { display: none; }

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot { cursor: pointer; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot { background: none; color: inherit; border: none; padding: 0 !important; font: inherit; }

.owl-carousel.owl-loaded { display: block; }

.owl-carousel.owl-loading { opacity: 0; display: block; }

.owl-carousel.owl-hidden { opacity: 0; }

.owl-carousel.owl-refresh .owl-item { visibility: hidden; }

.owl-carousel.owl-drag .owl-item { -ms-touch-action: pan-y; touch-action: pan-y; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.owl-carousel.owl-grab { cursor: move; cursor: grab; }

.owl-carousel.owl-rtl { direction: rtl; }

.owl-carousel.owl-rtl .owl-item { float: right; }

/* No Js */
.no-js .owl-carousel { display: block; }

/* Owl Carousel - Animate Plugin */
.owl-carousel .animated { animation-duration: 1000ms; animation-fill-mode: both; }

.owl-carousel .owl-animated-in { z-index: 0; }

.owl-carousel .owl-animated-out { z-index: 1; }

.owl-carousel .fadeOut { animation-name: fadeOut; }

@keyframes fadeOut { 0% { opacity: 1; }
  100% { opacity: 0; } }

/* Owl Carousel - Auto Height Plugin */
.owl-height { transition: height 500ms ease-in-out; }

/* Owl Carousel - Lazy Load Plugin */
.owl-carousel .owl-item { /** This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong calculation of the height of the owl-item that breaks page layouts */ }

.owl-carousel .owl-item .owl-lazy { opacity: 0; transition: opacity 400ms ease; }

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) { max-height: 0; }

.owl-carousel .owl-item img.owl-lazy { transform-style: preserve-3d; }

/* Owl Carousel - Video Plugin */
.owl-carousel .owl-video-wrapper { position: relative; height: 100%; background: #000; }

.owl-carousel .owl-video-play-icon { position: absolute; height: 80px; width: 80px; left: 50%; top: 50%; margin-left: -40px; margin-top: -40px; background: url("owl.video.play.png") no-repeat; cursor: pointer; z-index: 1; -webkit-backface-visibility: hidden; transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover { -ms-transform: scale(1.3, 1.3); transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon { display: none; }

.owl-carousel .owl-video-tn { opacity: 0; height: 100%; background-position: center center; background-repeat: no-repeat; background-size: contain; transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame { position: relative; z-index: 1; height: 100%; width: 100%; }

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] { transition-duration: 50ms; }

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate { transition-delay: 50ms; }

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] { transition-duration: .1s; }

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate { transition-delay: .1s; }

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] { transition-duration: .15s; }

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate { transition-delay: .15s; }

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] { transition-duration: .2s; }

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate { transition-delay: .2s; }

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] { transition-duration: .25s; }

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate { transition-delay: .25s; }

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] { transition-duration: .3s; }

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate { transition-delay: .3s; }

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] { transition-duration: .35s; }

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate { transition-delay: .35s; }

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] { transition-duration: .4s; }

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate { transition-delay: .4s; }

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] { transition-duration: .45s; }

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate { transition-delay: .45s; }

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] { transition-duration: .5s; }

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate { transition-delay: .5s; }

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] { transition-duration: .55s; }

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate { transition-delay: .55s; }

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] { transition-duration: .6s; }

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate { transition-delay: .6s; }

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] { transition-duration: .65s; }

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate { transition-delay: .65s; }

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] { transition-duration: .7s; }

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate { transition-delay: .7s; }

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] { transition-duration: .75s; }

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate { transition-delay: .75s; }

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] { transition-duration: .8s; }

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate { transition-delay: .8s; }

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] { transition-duration: .85s; }

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate { transition-delay: .85s; }

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] { transition-duration: .9s; }

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate { transition-delay: .9s; }

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] { transition-duration: .95s; }

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate { transition-delay: .95s; }

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] { transition-duration: 1s; }

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate { transition-delay: 1s; }

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] { transition-duration: 1.05s; }

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate { transition-delay: 1.05s; }

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] { transition-duration: 1.1s; }

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate { transition-delay: 1.1s; }

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] { transition-duration: 1.15s; }

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate { transition-delay: 1.15s; }

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] { transition-duration: 1.2s; }

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate { transition-delay: 1.2s; }

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] { transition-duration: 1.25s; }

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate { transition-delay: 1.25s; }

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] { transition-duration: 1.3s; }

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate { transition-delay: 1.3s; }

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] { transition-duration: 1.35s; }

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate { transition-delay: 1.35s; }

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] { transition-duration: 1.4s; }

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate { transition-delay: 1.4s; }

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] { transition-duration: 1.45s; }

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate { transition-delay: 1.45s; }

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] { transition-duration: 1.5s; }

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate { transition-delay: 1.5s; }

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] { transition-duration: 1.55s; }

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate { transition-delay: 1.55s; }

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] { transition-duration: 1.6s; }

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate { transition-delay: 1.6s; }

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] { transition-duration: 1.65s; }

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate { transition-delay: 1.65s; }

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] { transition-duration: 1.7s; }

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate { transition-delay: 1.7s; }

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] { transition-duration: 1.75s; }

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate { transition-delay: 1.75s; }

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] { transition-duration: 1.8s; }

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate { transition-delay: 1.8s; }

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] { transition-duration: 1.85s; }

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate { transition-delay: 1.85s; }

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] { transition-duration: 1.9s; }

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate { transition-delay: 1.9s; }

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] { transition-duration: 1.95s; }

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate { transition-delay: 1.95s; }

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] { transition-duration: 2s; }

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate { transition-delay: 2s; }

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] { transition-duration: 2.05s; }

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate { transition-delay: 2.05s; }

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] { transition-duration: 2.1s; }

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate { transition-delay: 2.1s; }

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] { transition-duration: 2.15s; }

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate { transition-delay: 2.15s; }

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] { transition-duration: 2.2s; }

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate { transition-delay: 2.2s; }

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] { transition-duration: 2.25s; }

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate { transition-delay: 2.25s; }

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] { transition-duration: 2.3s; }

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate { transition-delay: 2.3s; }

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] { transition-duration: 2.35s; }

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate { transition-delay: 2.35s; }

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] { transition-duration: 2.4s; }

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate { transition-delay: 2.4s; }

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] { transition-duration: 2.45s; }

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate { transition-delay: 2.45s; }

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] { transition-duration: 2.5s; }

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate { transition-delay: 2.5s; }

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] { transition-duration: 2.55s; }

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate { transition-delay: 2.55s; }

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] { transition-duration: 2.6s; }

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate { transition-delay: 2.6s; }

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] { transition-duration: 2.65s; }

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate { transition-delay: 2.65s; }

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] { transition-duration: 2.7s; }

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate { transition-delay: 2.7s; }

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] { transition-duration: 2.75s; }

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate { transition-delay: 2.75s; }

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] { transition-duration: 2.8s; }

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate { transition-delay: 2.8s; }

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] { transition-duration: 2.85s; }

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate { transition-delay: 2.85s; }

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] { transition-duration: 2.9s; }

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate { transition-delay: 2.9s; }

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] { transition-duration: 2.95s; }

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate { transition-delay: 2.95s; }

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] { transition-duration: 3s; }

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] { transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate { transition-delay: 3s; }

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] { transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] { transition-timing-function: ease; }

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] { transition-timing-function: ease-in; }

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] { transition-timing-function: ease-out; }

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] { transition-timing-function: ease-in-out; }

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] { transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] { transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] { transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] { transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] { transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] { transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] { transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] { transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] { transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] { transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] { transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] { transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] { transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] { transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] { transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos^=fade][data-aos^=fade] { opacity: 0; transition-property: opacity,transform; }

[data-aos^=fade][data-aos^=fade].aos-animate { opacity: 1; transform: translateZ(0); }

[data-aos=fade-up] { transform: translate3d(0, 100px, 0); }

[data-aos=fade-down] { transform: translate3d(0, -100px, 0); }

[data-aos=fade-right] { transform: translate3d(-100px, 0, 0); }

[data-aos=fade-left] { transform: translate3d(100px, 0, 0); }

[data-aos=fade-up-right] { transform: translate3d(-100px, 100px, 0); }

[data-aos=fade-up-left] { transform: translate3d(100px, 100px, 0); }

[data-aos=fade-down-right] { transform: translate3d(-100px, -100px, 0); }

[data-aos=fade-down-left] { transform: translate3d(100px, -100px, 0); }

[data-aos^=zoom][data-aos^=zoom] { opacity: 0; transition-property: opacity,transform; }

[data-aos^=zoom][data-aos^=zoom].aos-animate { opacity: 1; transform: translateZ(0) scale(1); }

[data-aos=zoom-in] { transform: scale(0.6); }

[data-aos=zoom-in-up] { transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos=zoom-in-down] { transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos=zoom-in-right] { transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos=zoom-in-left] { transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos=zoom-out] { transform: scale(1.2); }

[data-aos=zoom-out-up] { transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos=zoom-out-down] { transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos=zoom-out-right] { transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos=zoom-out-left] { transform: translate3d(100px, 0, 0) scale(1.2); }

[data-aos^=slide][data-aos^=slide] { transition-property: transform; }

[data-aos^=slide][data-aos^=slide].aos-animate { transform: translateZ(0); }

[data-aos=slide-up] { transform: translate3d(0, 100%, 0); }

[data-aos=slide-down] { transform: translate3d(0, -100%, 0); }

[data-aos=slide-right] { transform: translate3d(-100%, 0, 0); }

[data-aos=slide-left] { transform: translate3d(100%, 0, 0); }

[data-aos^=flip][data-aos^=flip] { backface-visibility: hidden; transition-property: transform; }

[data-aos=flip-left] { transform: perspective(2500px) rotateY(-100deg); }

[data-aos=flip-left].aos-animate { transform: perspective(2500px) rotateY(0); }

[data-aos=flip-right] { transform: perspective(2500px) rotateY(100deg); }

[data-aos=flip-right].aos-animate { transform: perspective(2500px) rotateY(0); }

[data-aos=flip-up] { transform: perspective(2500px) rotateX(-100deg); }

[data-aos=flip-up].aos-animate { transform: perspective(2500px) rotateX(0); }

[data-aos=flip-down] { transform: perspective(2500px) rotateX(100deg); }

[data-aos=flip-down].aos-animate { transform: perspective(2500px) rotateX(0); }

html.no-scroll { overflow: hidden; }

body { min-width: 375px; font-family: 'Poppins', sans-serif; font-size: 18px; line-height: 1.77778; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; color: #111029; }

@media only screen and (max-width: 1279px) { body { font-size: 16px; line-height: 1.75; } }

body.no-scroll { overflow: hidden; }

button, input, textarea, select { font-family: 'Poppins', sans-serif; }

.page { position: relative; overflow: hidden; min-height: 100vh; }

.center { max-width: 1334px; margin: 0 auto; padding: 0 82px; }

@media only screen and (max-width: 1023px) { .center { padding: 0 48px; } }

@media only screen and (max-width: 767px) { .center { padding: 0 32px; } }

.btn { min-width: 240px; height: 72px; border-radius: 20px; font-family: 'Poppins', sans-serif; font-size: 18px; font-weight: 500; transition: all .25s; }

@media only screen and (max-width: 1279px) { .btn { min-width: 200px; height: 64px; border-radius: 12px; font-size: 16px; } }

.btn .icon, .btn__text { display: inline-block; vertical-align: middle; }

.btn .icon:not(:first-child) { margin-left: 16px; }

.btn .icon:not(:last-child) { margin-right: 16px; }

.btn .icon { font-size: 16px; }

@media only screen and (max-width: 1279px) { .btn .icon { font-size: 14px; } }

.btn:hover { transform: translateY(-2px); }

.btn:active { transform: translateY(2px); }

.btn_purple { background: #2B6BFF; color: #fff; }

.btn_dark { background: #00113B; color: #fff; }

a.btn { display: inline-flex; justify-content: center; align-items: center; }

.h1, .h2, .h3, .h4, .h5, .h6 { font-weight: 600; }

.h1 { font-size: 72px; line-height: 1.16667; letter-spacing: -.5px; }

@media only screen and (max-width: 1279px) { .h1 { font-size: 56px; line-height: 1.28571; letter-spacing: -.4px; } }

@media only screen and (max-width: 767px) { .h1 { font-size: 42px; line-height: 1.33333; letter-spacing: -.1px; } }

.h2 { font-size: 56px; line-height: 1.28571; letter-spacing: -.4px; }

.h3 { font-size: 42px; line-height: 1.33333; letter-spacing: -.1px; }

@media only screen and (max-width: 1279px) { .h3 { font-size: 32px; line-height: 1.5; letter-spacing: -.15px; } }

@media only screen and (max-width: 767px) { .h3 { font-size: 20px; line-height: 1.4; } }

.h4 { font-size: 32px; line-height: 1.5; letter-spacing: -.15px; }

@media only screen and (max-width: 1279px) { .h4 { font-size: 24px; line-height: 1.33333; } }

.h5 { font-size: 24px; line-height: 1.33333; }

@media only screen and (max-width: 1279px) { .h5 { font-size: 20px; line-height: 1.4; } }

.h6 { font-size: 20px; line-height: 1.4; }

.stage { position: relative; margin-bottom: 24px; padding-left: 68px; font-size: 20px; line-height: 1.4; font-weight: 600; color: #2B6BFF; }

@media only screen and (max-width: 1279px) { .stage { margin-bottom: 16px; font-size: 16px; } }

.stage:before { content: ""; position: absolute; top: 15px; left: 0; width: 52px; height: 2px; background: #2B6BFF; }

@media only screen and (max-width: 1279px) { .stage:before { top: 10px; } }

.stage_pink { color: #111029; }

.stage_pink:before { background: #111029; }

.header { position: absolute; top: 0; left: 0; right: 0; z-index: 10; padding: 48px 0; }

@media only screen and (max-width: 1279px) { .header { padding: 32px 0; } }

@media only screen and (max-width: 767px) { .header { padding: 36px 0; } }

.header__center { display: flex; }

.header > .header__center { align-items: center; max-width: 100%; padding: 0 68px; }

@media only screen and (max-width: 1279px) { .header > .header__center { padding: 0 48px; } }

@media only screen and (max-width: 767px) { .header > .header__center { padding: 0 32px; } }

.header__center .header__logo { margin-right: 93px; }

@media only screen and (max-width: 1279px) { .header__center .header__logo { margin-right: 68px; } }

@media only screen and (max-width: 1023px) { .header__center .header__logo { margin-right: 40px; } }

@media only screen and (max-width: 767px) { .header__center .header__logo { position: relative; z-index: 15; margin-right: auto; } }

.header__logo { font-size: 0; }

.header__logo .header__pic { width: 90px; }

@media only screen and (max-width: 1279px) { .header__logo .header__pic { width: 40px; } }

@media only screen and (max-width: 767px) { .header__logo .header__pic { width: 70px; } }

.header__social { display: flex; align-items: center; }

.header__social_main { margin-right: auto; }

@media only screen and (max-width: 767px) { .header__social_main { display: none; } }

.header__social_inner { position: absolute; left: calc(50% - 184px); bottom: 82px; }

@media only screen and (max-width: 1279px) { .header__social_inner { left: 380px; bottom: 56px; } }

@media only screen and (max-width: 767px) { .header__social_inner { position: relative; left: auto; bottom: auto; margin-top: 20px; } }

@media only screen and (max-width: 639px) { .header__social_inner { flex-direction: column; align-items: flex-start; margin-top: 32px; } }

.header__social .header__link { display: flex; align-items: center; font-size: 16px; font-weight: 500; line-height: 1.5; color: #9F9FA9; transition: color .25s; }

@media only screen and (max-width: 1279px) { .header__social .header__link { font-size: 14px; } }

.header__social .header__link .icon { margin-right: 15px; font-size: 16px; fill: #9F9FA9; transition: fill .25s; }

.header__social .header__link:hover { color: #111029; }

.header__social .header__link:hover .icon { fill: #111029; }

.header__social .header__link:not(:last-child) { margin-right: 68px; }

@media only screen and (max-width: 1023px) { .header__social .header__link:not(:last-child) { margin-right: 40px; } }

@media only screen and (max-width: 639px) { .header__social .header__link:not(:last-child) { margin: 0 0 16px; } }

.header__burger { position: relative; z-index: 12; width: 64px; height: 64px; margin-right: -17px; border-radius: 50%; padding: 5px; background: transparent; font-size: 0; transition: all .25s; }

@media only screen and (max-width: 1279px) { .header__burger { width: 48px; height: 48px; margin-right: -10px; } }

@media only screen and (max-width: 767px) { .header__burger { width: 32px; height: 32px; margin-right: -5px; padding: 0; } }

.header__burger:before, .header__burger:after { content: ""; display: inline-block; width: 32px; height: 2px; background: #fff; transition: transform .25s; }

@media only screen and (max-width: 1279px) { .header__burger:before, .header__burger:after { width: 24px; } }

@media only screen and (max-width: 767px) { .header__burger:before, .header__burger:after { background: #111029; } }

.header__burger:before { margin-bottom: 4px; }

@media only screen and (max-width: 1279px) { .header__burger:before { margin-bottom: 2.5px; } }

.header__burger:after { margin-top: 4px; }

@media only screen and (max-width: 1279px) { .header__burger:after { margin-top: 2.5px; } }

.header__burger_dark:before, .header__burger_dark:after { background: #00113B; }

.header__burger:hover { opacity: .85; }

.header__burger.active { background: #2B6BFF; }

@media only screen and (max-width: 767px) { .header__burger.active { background: none; } }

.header__burger.active:before, .header__burger.active:after { background: #fff; }

@media only screen and (max-width: 767px) { .header__burger.active:before, .header__burger.active:after { background: #00113B; } }

.header__burger.active:before { transform: translateY(5px) rotate(45deg); }

@media only screen and (max-width: 1279px) { .header__burger.active:before { transform: translateY(3.5px) rotate(45deg); } }

.header__burger.active:after { transform: translateY(-5px) rotate(-45deg); }

@media only screen and (max-width: 1279px) { .header__burger.active:after { transform: translateY(-3.5px) rotate(-45deg); } }

.header__wrapper { position: fixed; top: 0; left: 0; right: 0; bottom: 0; display: flex; align-items: center; z-index: 10; background: #fff; visibility: hidden; opacity: 0; transition: all .25s; }

@media only screen and (max-width: 767px) { .header__wrapper { display: block; } }

@media only screen and (max-width: 639px) { .header__wrapper { visibility: visible; opacity: 1; transform: translateX(100%); background: #fff url("../img/bg-header-mobile.png") no-repeat 100% 150%/100% auto; } }

.header__wrapper.visible { visibility: visible; opacity: 1; }

@media only screen and (max-width: 639px) { .header__wrapper.visible { transform: translateX(0); } }

.header__preview { position: absolute; top: 0; left: 0; bottom: 0; width: 400px; padding: 64px 0 0 68px; background: #2c6bff; overflow: hidden; }

@media only screen and (max-width: 1279px) { .header__preview { width: 300px; padding: 46px 0 0 48px; } }

@media only screen and (max-width: 767px) { .header__preview { display: none; } }

.header__img { position: absolute; top: 50%; left: 0; transform: translateY(-50%); height: 100%; max-height: 100%; font-size: 0; }

@media only screen and (max-width: 1023px) { .header__img { height: 80%; max-height: 80%; } }

.header__img .header__pic { height: 100%; width: auto; }

.header__wrapper .header__center { align-items: stretch; width: 100%; max-width: 1204px; padding-left: 418px; }

@media only screen and (max-width: 1279px) { .header__wrapper .header__center { max-width: 100%; padding-left: 380px; } }

@media only screen and (max-width: 1023px) { .header__wrapper .header__center { flex-direction: column-reverse; } }

@media only screen and (max-width: 767px) { .header__wrapper .header__center { display: block; padding: 120px 32px 30px; } }

.header__contacts { display: flex; flex-direction: column; align-items: flex-start; flex-shrink: 0; width: 340px; margin-right: auto; }

@media only screen and (max-width: 1279px) { .header__contacts { width: 280px; } }

@media only screen and (max-width: 1023px) { .header__contacts { margin: 120px 0 0; } }

@media only screen and (max-width: 767px) { .header__contacts { display: none; } }

.header__line { display: flex; color: #111029; }

@media only screen and (max-width: 1279px) { .header__line { font-size: 16px; line-height: 1.75; } }

.header__line:not(:last-child) { margin-bottom: 24px; }

.header__icon { display: flex; justify-content: center; align-items: center; flex-shrink: 0; width: 24px; height: 30px; margin-right: 45px; font-size: 0; }

@media only screen and (max-width: 1279px) { .header__icon { margin-right: 25px; width: 17px; height: 28px; } }

.header__icon .icon { fill: #2B6BFF; }

.header__icon .icon-email { font-size: 20px; }

@media only screen and (max-width: 1279px) { .header__icon .icon-email { font-size: 14px; } }

.header__icon .icon-place { font-size: 23px; }

@media only screen and (max-width: 1279px) { .header__icon .icon-place { font-size: 17px; } }

.header__icon .icon-phone { font-size: 24px; }

@media only screen and (max-width: 1279px) { .header__icon .icon-phone { font-size: 18px; } }

.header__nav { display: flex; flex-direction: column; align-items: flex-start; margin-top: 30px; }

.header__item { overflow: hidden; font-size: 72px; line-height: 1.16667; letter-spacing: -.5px; font-weight: 600; color: #111029; transition: color .25s; }

@media only screen and (max-width: 1279px) { .header__item { font-size: 42px; line-height: 1.33333; } }

@media only screen and (max-width: 767px) { .header__item { font-size: 24px; line-height: 1.5; } }

@media only screen and (max-width: 639px) { .header__item { font-size: 32px; } }

.header__item span { display: block; transform: translateY(110%); transition: transform .25s; transition-delay: .3s; }

.header__item:hover { color: #2B6BFF; }

.header__item:not(:last-child) { margin-bottom: 16px; }

@media only screen and (max-width: 1279px) { .header__item:not(:last-child) { margin-bottom: 8px; } }

@media only screen and (max-width: 767px) { .header__item:not(:last-child) { margin-bottom: 25px; } }

.header__wrapper.visible .header__item span { transform: translateX(0); }

.header__wrapper.visible .header__item:first-child span { transition-delay: .2s; }

.header__wrapper.visible .header__item:nth-child(2) span { transition-delay: .4s; }

.header__wrapper.visible .header__item:nth-child(3) span { transition-delay: .6s; }

.header__wrapper.visible .header__item:nth-child(4) span { transition-delay: .8s; }

.main { position: relative; padding: 270px 0 105px; }

@media only screen and (max-width: 1279px) { .main { padding: 170px 0 60px; } }

@media only screen and (max-width: 767px) { .main { padding: 130px 0 0; } }

@media only screen and (max-width: 639px) { .main:before { content: ""; position: absolute; top: 420px; right: 0; z-index: -1; width: 226px; height: 370px; background: #1B1B87; clip-path: polygon(0 120px, 100% 0, 100% 100%, 0 100%); } }

.main__bg { position: absolute; top: 0; right: 0; z-index: -5; }

@media only screen and (max-width: 1439px) { .main__bg { right: -50px; } }

@media only screen and (max-width: 1365px) { .main__bg { right: -100px; } }

@media only screen and (max-width: 1279px) { .main__bg { right: 0; } }

@media only screen and (max-width: 1023px) { .main__bg { right: -150px; } }

@media only screen and (max-width: 767px) { .main__bg { display: none; } }

.main__bg .main__pic { max-width: 1440px; }

@media only screen and (max-width: 1279px) { .main__bg .main__pic { max-height: 852px; } }

.main__wrap { max-width: 490px; }

@media only screen and (max-width: 1279px) { .main__wrap { max-width: 380px; } }

@media only screen and (max-width: 767px) { .main__wrap { max-width: 100%; } }

@media only screen and (max-width: 639px) { .main__wrap { max-width: 230px; } }

.main__title { perspective: 400px; transform-style: preserve-3d; margin-bottom: 181px; }

@media only screen and (max-width: 1279px) { .main__title { margin-bottom: 114px; } }

@media only screen and (max-width: 767px) { .main__title { margin-bottom: 40px; font-size: 32px; line-height: 1.5; letter-spacing: -.15px; } }

.main__title span { display: block; }

.main__view { position: absolute; top: 320px; right: 0; width: 910px; z-index: -1; }

@media only screen and (max-width: 1439px) { .main__view { right: -50px; } }

@media only screen and (max-width: 1365px) { .main__view { right: -100px; } }

@media only screen and (max-width: 1279px) { .main__view { top: 218px; right: -8px; width: 660px; } }

@media only screen and (max-width: 1023px) { .main__view { top: 327px; right: -56px; width: 500px; } }

@media only screen and (max-width: 767px) { .main__view { position: relative; top: auto; left: auto; right: auto; width: 480px; margin: 40px 0 0 12%; } }

@media only screen and (max-width: 639px) { .main__view { margin: 130px 0 0 -63px; } }

.main__view[data-aos] .main__preview { transform: translateY(40px); opacity: 0; transition: all .3s; }

.main__view[data-aos].aos-animate .main__preview { transform: translateY(0); opacity: 1; }

.main__view[data-aos].aos-animate .main__preview:first-child { transition-delay: .9s; }

.main__view[data-aos].aos-animate .main__preview:nth-child(2) { transition-delay: .15s; }

.main__view[data-aos].aos-animate .main__preview:nth-child(3) { transition-delay: .3s; }

.main__view[data-aos].aos-animate .main__preview:nth-child(4) { transition-delay: .45s; }

.main__view[data-aos].aos-animate .main__preview:nth-child(5) { transition-delay: .6s; }

.main__view[data-aos].aos-animate .main__preview:nth-child(6) { transition-delay: .75s; }

.main__view[data-aos].aos-animate .main__preview:nth-child(7) { transition-delay: 1.05s; }

.main__view .main__pic, .main__figure .main__pic { width: 100%; }

.main__preview:nth-child(2) { left: 43.7%; top: -16%; z-index: 3; width: 17%; }

.main__preview:nth-child(3) { left: 35.6%; top: -14%; z-index: 2; width: 33.5%; }

.main__preview:nth-child(4) { left: 19.1%; top: 19.9%; z-index: 1; width: 33.5%; }

.main__preview:nth-child(5) { right: 14.2%; top: 20%; z-index: 1; width: 25%; }

.main__preview:nth-child(6) { right: 43.3%; top: 35.5%; z-index: 3; width: 8%; }

.main__preview:nth-child(7) { right: 2%; bottom: 8.4%; z-index: 3; width: 27.3%; }

.main__preview:not(:first-child) { position: absolute; }

.main__figure { position: absolute; z-index: -3; }

.main__figure:first-child { top: 152px; right: 590px; width: 231px; }

@media only screen and (max-width: 1365px) { .main__figure:first-child { right: 520px; } }

@media only screen and (max-width: 1279px) { .main__figure:first-child { top: 240px; right: 466px; width: 132px; } }

@media only screen and (max-width: 1023px) { .main__figure:first-child { top: 140px; right: 320px; } }

@media only screen and (max-width: 767px) { .main__figure:first-child { top: 110px; right: 10%; } }

.main__figure:nth-child(2) { right: 738px; bottom: 21.2%; width: 213px; }

@media only screen and (max-width: 1365px) { .main__figure:nth-child(2) { right: 700px; } }

@media only screen and (max-width: 1279px) { .main__figure:nth-child(2) { right: 541px; bottom: 16.3%; width: 180px; } }

@media only screen and (max-width: 1023px) { .main__figure:nth-child(2) { right: 400px; } }

@media only screen and (max-width: 767px) { .main__figure:nth-child(2) { top: 260px; bottom: auto; right: -30px; } }

.main__figure:nth-child(3) { right: 627px; bottom: 6.2%; width: 250px; }

@media only screen and (max-width: 1365px) { .main__figure:nth-child(3) { right: 530px; } }

@media only screen and (max-width: 1279px) { .main__figure:nth-child(3) { right: 467px; bottom: 4.3%; width: 176px; } }

@media only screen and (max-width: 1023px) { .main__figure:nth-child(3) { right: 300px; bottom: 3%; } }

@media only screen and (max-width: 767px) { .main__figure:nth-child(3) { display: none; } }

.main__bg, .main__preview, .main__figure { font-size: 0; pointer-events: none; }

@media only screen and (max-width: 767px) { .main .scroll { font-size: 0; } }

.scroll { display: inline-flex; align-items: center; font-size: 16px; line-height: 1.5; font-weight: 500; color: #fff; }

@media only screen and (max-width: 1279px) { .scroll { font-size: 14px; } }

.scroll__icon { display: flex; justify-content: center; align-items: center; flex-shrink: 0; width: 80px; height: 80px; margin-right: 24px; border-radius: 50%; background: #fff; box-shadow: 0 5px 20px rgba(17, 16, 41, 0.05); font-size: 0; transition: transform .25s; }

@media only screen and (max-width: 1279px) { .scroll__icon { width: 56px; height: 56px; margin-right: 16px; } }

@media only screen and (max-width: 767px) { .scroll__icon { box-shadow: 0 10px 25px rgba(17, 16, 41, 0.1); } }

@media only screen and (max-width: 1279px) { .scroll__pic { width: 16px; } }

.scroll:hover .scroll__icon { transform: translateY(3px); }

.details { position: relative; padding: 258px 0 128px; }

@media only screen and (max-width: 1439px) { .details { padding-top: 200px; } }

@media only screen and (max-width: 1279px) { .details { padding: 128px 0 96px; } }

@media only screen and (max-width: 767px) { .details { padding: 14px 0 64px; } }

.details__center { position: relative; z-index: 5; }

.details__head { margin-bottom: 145px; }

@media only screen and (max-width: 1279px) { .details__head { margin-bottom: 55px; } }

@media only screen and (max-width: 767px) { .details__head { padding-bottom: 56px; position: relative; }
  .details__head:before { content: ""; position: absolute; left: 0; bottom: 0; width: 136px; height: 1px; background: #ECECEC; } }

.details__title { margin-bottom: 72px; font-size: 132px; line-height: 1.02; letter-spacing: -1.3px; font-weight: 600; }

@media only screen and (max-width: 1439px) { .details__title { font-size: 112px; } }

@media only screen and (max-width: 1279px) { .details__title { margin-bottom: 32px; font-size: 96px; line-height: 1.16667; } }

@media only screen and (max-width: 1023px) { .details__title { font-size: 80px; } }

@media only screen and (max-width: 767px) { .details__title { margin-bottom: 48px; font-size: 72px; line-height: 1.16667; } }

@media only screen and (max-width: 767px) { .details__btn { text-align: center; } }

.details__btn .btn { min-width: 266px; height: 80px; }

@media only screen and (max-width: 1279px) { .details__btn .btn { min-width: 221px; height: 64px; } }

@media only screen and (max-width: 639px) { .details__btn .btn { min-width: 100%; } }

.details__container { margin: 136px -9px 0; }

@media only screen and (max-width: 1279px) { .details__container { margin-top: 64px; } }

.details__item { position: relative; padding: 85px 55px 65px; background: #fff; border-radius: 40px; text-align: center; }

@media only screen and (max-width: 1365px) { .details__item { padding: 56px 32px 46px; } }

@media only screen and (max-width: 767px) { .details__item { padding: 48px 44px; border-radius: 28px; } }

.details__item:before { content: ""; position: absolute; top: 32px; left: 20px; right: 20px; bottom: -43px; z-index: -1; border-radius: 24px; background: #DBDEE1; filter: blur(56.985px); pointer-events: none; transition: all .25s; }

@media only screen and (max-width: 1279px) { .details__item:before { top: 24px; left: 25px; right: 25px; bottom: -52px; border-radius: 17px; filter: blur(26.985px); } }

@media only screen and (max-width: 767px) { .details__item:before { left: 15px; right: 15px; } }

.details__preview { position: relative; width: 240px; height: 240px; margin: 0 auto 68px; border-radius: 50%; font-size: 0; transition: transform .25s; }

@media only screen and (max-width: 1365px) { .details__preview { margin-bottom: 46px; } }

@media only screen and (max-width: 1279px) { .details__preview { width: 170px; height: 170px; } }

@media only screen and (max-width: 767px) { .details__preview { width: 200px; height: 200px; margin-bottom: 24px; } }

.details__preview_cursor { background: #FFD301; }

.details__preview_cursor .details__img { top: 15px; right: -22px; width: 209px; }

@media only screen and (max-width: 1279px) { .details__preview_cursor .details__img { top: 10px; right: -15px; width: 148px; } }

@media only screen and (max-width: 767px) { .details__preview_cursor .details__img { width: 168px; } }

.details__preview_hand { background: #2B6BFF; }

.details__preview_hand .details__img { top: 50%; left: 50%; transform: translate(-50%, -50%); width: 124px; }

@media only screen and (max-width: 1279px) { .details__preview_hand .details__img { width: 90px; } }

@media only screen and (max-width: 767px) { .details__preview_hand .details__img { width: 100px; } }

.details__preview_color { background: #111029; }

.details__preview_color .details__img { top: 22px; right: -5px; width: 248px; }

@media only screen and (max-width: 1279px) { .details__preview_color .details__img { top: 15px; right: 0; width: 155px; } }

@media only screen and (max-width: 767px) { .details__preview_color .details__img { width: 175px; } }

.details__img { position: absolute; pointer-events: none; }

.details__img .details__pic { width: 100%; }

.details__category { margin-bottom: 24px; }

@media only screen and (max-width: 1279px) { .details__category { margin-bottom: 16px; } }

.details__text { margin-bottom: 40px; color: #6b6b6b; }

@media only screen and (max-width: 1279px) { .details__text { margin-bottom: 32px; } }

@media only screen and (max-width: 1279px) { .details__item .btn { min-width: 171px; height: 51px; } }

.details__figure { position: absolute; z-index: -1; font-size: 0; pointer-events: none; }

.details__figure:first-child { top: 200px; right: calc(50% - 474px); z-index: 2; width: 244px; }

@media only screen and (max-width: 1365px) { .details__figure:first-child { right: calc(50% - 374px); } }

@media only screen and (max-width: 1279px) { .details__figure:first-child { top: 285px; right: calc(50% - 354px); width: 155px; opacity: .5; } }

@media only screen and (max-width: 1023px) { .details__figure:first-child { right: calc(50% - 300px); } }

@media only screen and (max-width: 767px) { .details__figure:first-child { display: none; } }

.details__figure:nth-child(2) { top: 744px; right: calc(50% - 452px); z-index: 3; width: 371px; }

@media only screen and (max-width: 1365px) { .details__figure:nth-child(2) { top: 680px; right: calc(50% - 350px); } }

@media only screen and (max-width: 1279px) { .details__figure:nth-child(2) { top: 495px; right: calc(50% - 298px); width: 242px; } }

@media only screen and (max-width: 1023px) { .details__figure:nth-child(2) { right: calc(50% - 200px); } }

@media only screen and (max-width: 767px) { .details__figure:nth-child(2) { top: 336px; right: 0; width: 150px; } }

.details__figure:nth-child(3) { top: 129px; right: calc(50% - 1270px); z-index: 1; width: 1260px; }

@media only screen and (max-width: 1365px) { .details__figure:nth-child(3) { right: calc(50% - 1070px); width: 1000px; } }

@media only screen and (max-width: 1279px) { .details__figure:nth-child(3) { top: 75px; right: calc(50% - 860px); width: 800px; } }

@media only screen and (max-width: 1023px) { .details__figure:nth-child(3) { right: calc(50% - 820px); } }

@media only screen and (max-width: 767px) { .details__figure:nth-child(3) { top: 58px; right: -160px; width: 430px; } }

.details__figure .details__pic { width: 100%; }

.details__contact { position: absolute; right: calc(50% - 585px); bottom: 131px; z-index: 2; color: #2B6BFF; transition: opacity .25s; }

@media only screen and (max-width: 1365px) { .details__contact { right: 82px; } }

@media only screen and (max-width: 1279px) { .details__contact { bottom: 103px; font-size: 16px; } }

@media only screen and (max-width: 767px) { .details__contact { display: none; } }

.details__contact:hover { opacity: .85; }

.details .owl-stage-outer { overflow: visible; }

.details .owl-item { padding: 0 9px; opacity: 0; visibility: hidden; transition: all .25s; }

.details .owl-item:hover { position: relative; z-index: 1; }

.details .owl-item:hover .details__btn { opacity: 1; visibility: visible; }

.details .owl-item.active { visibility: visible; opacity: 1; }

.owl-nav { position: relative; z-index: 1; display: flex; justify-content: center; align-items: center; margin-top: 67px; height: 40px; }

@media only screen and (max-width: 1279px) { .owl-nav { margin-top: 52px; } }

@media only screen and (max-width: 767px) { .owl-nav { height: 32px; margin-top: 36px; } }

.owl-prev, .owl-next { position: relative; width: 30px; height: 40px; font-size: 0; }

@media only screen and (max-width: 767px) { .owl-prev, .owl-next { height: 32px; } }

.owl-prev span, .owl-next span { display: inline-block; position: absolute; top: 50%; transform: translateY(-50%); width: 30px; height: 2px; background: #9F9FA9; font-size: 0; transition: all .25s; }

.owl-prev span:before, .owl-next span:before { content: ""; position: absolute; top: 50%; transform: translateY(-50%) scale(0); width: 40px; height: 40px; border: 2px solid #2B6BFF; border-radius: 50%; transition: transform .25s; }

@media only screen and (max-width: 767px) { .owl-prev span:before, .owl-next span:before { width: 34px; height: 34px; } }

.owl-prev .icon, .owl-next .icon { position: absolute; top: 50%; transform: translateY(-50%); font-size: 14px; fill: #9F9FA9; transition: fill .25s; }

.owl-prev:hover span, .owl-next:hover span { width: 52px; background: #111029; }

.owl-prev:hover span:before, .owl-next:hover span:before { transform: translateY(-50%) scale(1); }

.owl-prev:hover .icon, .owl-next:hover .icon { fill: #111029; }

.owl-prev { margin-right: 27px; }

@media only screen and (max-width: 767px) { .owl-prev { margin-right: 20px; } }

.owl-prev span { right: 0; }

.owl-prev span .icon { left: 0; }

.owl-prev span:before { left: -17px; }

@media only screen and (max-width: 767px) { .owl-prev span:before { left: -14px; } }

.owl-next span { left: 0; }

.owl-next span .icon { right: 0; }

.owl-next span:before { right: -17px; }

@media only screen and (max-width: 767px) { .owl-next span:before { right: -14px; } }

.owl-dots { display: flex; padding: 0 32px; }

.owl-dot { position: relative; flex-shrink: 0; width: 8px; height: 8px; }

.owl-dot:before { content: ""; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 4px; height: 4px; border-radius: 50%; background: #D8D8D8; transition: all .25s; }

.owl-dot.active:before { transform: translate(-50%, -50%) scale(2); background: #2B6BFF; }

.owl-dot:not(:last-child) { margin-right: 12px; }

body:not(.touch-device) .details__item:before, body:not(.touch-device) .details__item .details__btn { visibility: hidden; opacity: 0; }

body:not(.touch-device) .owl-item:hover .details__item:before, body:not(.touch-device) .owl-item:hover .details__item .details__btn { visibility: visible; opacity: 1; }

body:not(.touch-device) .owl-item:hover .details__preview { transform: scale(1.15); }

.work { position: relative; padding: 246px 0 80px; background: #1B1B87; }

@media only screen and (max-width: 1279px) { .work { padding: 143px 0; } }

@media only screen and (max-width: 767px) { .work { padding: 56px 0 475px; overflow: hidden; } }

.work__circles { position: absolute; top: 0; left: 0; width: 100%; height: 100%; overflow: hidden; pointer-events: none; }

.work__circles:before, .work__circles:after { content: ""; position: absolute; top: 50%; transform: translateY(-50%); border-radius: 50%; border: 1px solid rgba(255, 255, 255, 0.15); }

@media only screen and (max-width: 767px) { .work__circles:before, .work__circles:after { left: 50%; transform: translate(-50%, 0); border: 1px solid rgba(255, 255, 255, 0.1); } }

.work__circles:before { width: 1024px; height: 1024px; right: calc(50% - 860px); }

@media only screen and (max-width: 1279px) { .work__circles:before { right: calc(50% - 610px); width: 730px; height: 730px; } }

@media only screen and (max-width: 767px) { .work__circles:before { top: 104px; width: 368px; height: 368px; } }

.work__circles:after { width: 1670px; height: 1670px; right: calc(50% - 1186px); }

@media only screen and (max-width: 1279px) { .work__circles:after { right: calc(50% - 950px); width: 1290px; height: 1290px; } }

@media only screen and (max-width: 767px) { .work__circles:after { top: -15px; right: auto; width: 600px; height: 600px; } }

.work__wrap { position: relative; z-index: 3; max-width: 570px; color: #fff; }

@media only screen and (max-width: 1279px) { .work__wrap { max-width: 400px; } }

@media only screen and (max-width: 1279px) { .work__stage { color: #fff; }
  .work__stage:before { background: #fff; } }

.work__title { margin-bottom: 48px; }

@media only screen and (max-width: 1279px) { .work__title { margin-bottom: 32px; } }

.work__text { max-width: 470px; margin-bottom: 202px; }

@media only screen and (max-width: 1365px) { .work__text { margin-bottom: 140px; } }

@media only screen and (max-width: 1279px) { .work__text { max-width: 270px; margin: 0; } }

.work .scroll { color: #fff; }

@media only screen and (max-width: 1279px) { .work .scroll { display: none; } }

.work__figures { position: absolute; top: 3px; right: calc(50% - 717px); z-index: 2; width: 1290px; font-size: 0; }

@media only screen and (max-width: 1279px) { .work__figures { right: calc(50% - 514px); width: 910px; } }

@media only screen and (max-width: 1023px) { .work__figures { right: calc(50% - 480px); } }

@media only screen and (max-width: 767px) { .work__figures { top: auto; right: 50%; bottom: -108px; width: 850px; transform: translateX(30%); } }

.work__figure { width: 100%; pointer-events: none; }

.work__figure:first-child { position: relative; top: -70px; }

@media only screen and (max-width: 767px) { .work__figure:first-child { top: 0; } }

.work__figure:nth-child(2) { top: 15.8%; left: -10%; z-index: 2; width: 90%; }

.work__figure:nth-child(3) { top: 28.8%; left: 39%; z-index: 2; width: 72%; }

.work__figure:nth-child(4) { top: 25%; left: 13.4%; z-index: 2; width: 75%; }

.work__figure:nth-child(5) { top: 9.7%; left: -3%; z-index: 3; width: 93%; }

.work__figure:nth-child(6) { top: 7.5%; left: 37%; z-index: 3; width: 67%; }

.work__figure:nth-child(7) { top: 38%; left: 31.3%; z-index: 4; width: 66%; }

.work__figure:not(:first-child) { position: absolute; }

.work__pic { width: 100%; }

.work__preview { position: absolute; top: 67px; left: calc(50% - 634px); width: 193px; font-size: 0; }

@media only screen and (max-width: 1279px) { .work__preview { top: 48px; left: 42px; width: 143px; } }

@media only screen and (max-width: 767px) { .work__preview { top: 210px; left: auto; right: 35px; } }

.wrapper_gradient { background: radial-gradient(75.22% 75.22% at 20.49% 12.79%, #FFFFFF 0%, rgba(228, 231, 233, 0.502295) 100%); }

@media only screen and (max-width: 767px) { .wrapper_gradient { background: none; } }

.wrapper_dark { position: relative; }

.wrapper_dark:before, .wrapper_dark:after { content: ""; position: absolute; }

@media only screen and (max-width: 767px) { .wrapper_dark:before, .wrapper_dark:after { display: none; } }

.wrapper_dark:before { top: 700px; right: 0; left: 0; bottom: 0; clip-path: polygon(0 600px, 100% 0, 100% 100%, 0 100%); background: #00113B; }

@media only screen and (max-width: 1279px) { .wrapper_dark:before { top: 500px; clip-path: polygon(0 440px, 100% 0, 100% 100%, 0 100%); } }

.wrapper_dark:after { top: 741px; left: calc(50% - 720px); width: 140px; height: 560px; clip-path: polygon(0 58px, 100% 0, 100% calc(100% - 58px), 0 100%); background: #2B6BFF; }

@media only screen and (max-width: 1439px) { .wrapper_dark:after { top: 760px; } }

@media only screen and (max-width: 1365px) { .wrapper_dark:after { display: none; } }

@media only screen and (min-width: 1441px) { .wrapper_dark:after { display: none; } }

.cases { position: relative; padding-top: 128px; }

@media only screen and (max-width: 1279px) { .cases { padding-top: 96px; } }

@media only screen and (max-width: 767px) { .cases { padding-top: 64px; } }

.cases__center { position: relative; z-index: 2; }

.cases__title { margin-bottom: 80px; }

@media only screen and (max-width: 1279px) { .cases__title { margin-bottom: 32px; } }

.cases__nav { display: flex; margin-bottom: 88px; }

@media only screen and (max-width: 1279px) { .cases__nav { margin-bottom: 48px; } }

@media only screen and (max-width: 767px) { .cases__nav { display: none; } }

.cases__link { position: relative; padding-bottom: 10px; font-size: 16px; line-height: 1.5; font-weight: 500; color: #9F9FA9; transition: color .25s; }

@media only screen and (max-width: 1279px) { .cases__link { font-size: 14px; } }

.cases__link:before { content: ""; position: absolute; left: 0; right: 0; bottom: 0; width: 0; height: 2px; margin: 0 auto; background: #2B6BFF; border-radius: 1px; transition: width .25s; }

.cases__link:hover { color: #111029; }

.cases__link.active { color: #111029; }

.cases__link.active:before { width: 100%; }

.cases__link:not(:last-child) { margin-right: 96px; }

@media only screen and (max-width: 1279px) { .cases__link:not(:last-child) { margin-right: 48px; } }

.cases__list { display: flex; flex-wrap: wrap; margin: -32px -16px 0; }

@media only screen and (max-width: 1279px) { .cases__list { margin: -24px -12px 0; } }

@media only screen and (max-width: 767px) { .cases__list { display: block; width: calc(100% + 64px); margin: 0 -32px; } }

.cases__item { position: relative; display: block; background: #fff; border-radius: 24px; }

@media only screen and (max-width: 1279px) { .cases__item { border-radius: 17px; } }

@media only screen and (max-width: 767px) { .cases__item { background: none; border-radius: 0; } }

.cases__item:before { content: ""; position: absolute; top: 24px; left: 30px; right: 30px; bottom: -96px; z-index: -1; border-radius: 24px; filter: blur(86.985px); background: #DBDEE1; visibility: hidden; opacity: 0; pointer-events: none; transition: all .25s; }

@media only screen and (max-width: 1279px) { .cases__item:before { top: 30px; left: 24px; right: 24px; bottom: -51px; border-radius: 17px; filter: blur(77px); } }

@media only screen and (max-width: 767px) { .cases__item:before { display: none; } }

.cases__item:hover:before { visibility: visible; opacity: 1; }

.cases__list .cases__item { display: flex; flex-direction: column; flex: 0 0 calc(50% - 32px); width: calc(50% - 32px); margin: 32px 16px 0; }

@media only screen and (max-width: 1279px) { .cases__list .cases__item { flex: 0 0 calc(50% - 24px); width: calc(50% - 24px); margin: 24px 12px 0; } }

@media only screen and (max-width: 767px) { .cases__list .cases__item { width: 100%; margin: 0; } }

.cases__preview { position: relative; font-size: 0; }

.cases__pic { width: 100%; }

.cases__preview .cases__pic { border-radius: 24px 24px 0 0; }

@media only screen and (max-width: 1279px) { .cases__preview .cases__pic { border-radius: 17px 17px 0 0; } }

@media only screen and (max-width: 767px) { .cases__preview .cases__pic { border-radius: 0; } }

.cases__box { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) scale(0.6); display: flex; justify-content: center; align-items: center; width: 150px; height: 150px; padding: 30px; border-radius: 50%; background: #00113B; text-align: center; opacity: 0; font-size: 18px; line-height: 1.33333; font-weight: 500; color: #fff; transition: all .25s; }

@media only screen and (max-width: 767px) { .cases__box { display: none; } }

.cases__item:hover .cases__box { opacity: 1; transform: translate(-50%, -50%) scale(1); }

.cases__body { flex-grow: 1; padding: 48px 48px 76px; border-radius: 0 0 24px 24px; background: #fff; }

@media only screen and (max-width: 1279px) { .cases__body { padding: 40px; border-radius: 0 0 17px 17px; } }

@media only screen and (max-width: 1023px) { .cases__body { padding: 32px; } }

.cases__info { margin-bottom: 8px; color: #111029; }

@media only screen and (max-width: 1279px) { .cases__info { margin-bottom: 4px; } }

.cases__text { color: #6b6b6b; }

.cases__figure { position: absolute; font-size: 0; pointer-events: none; }

@media only screen and (max-width: 767px) { .cases__figure { display: none; } }

.cases__figure:first-child { top: 128px; left: calc(50% - 181px); width: 363px; }

@media only screen and (max-width: 1279px) { .cases__figure:first-child { top: 80px; left: calc(50% - 135px); width: 280px; } }

@media only screen and (max-width: 1023px) { .cases__figure:first-child { left: 400px; } }

.cases__figure:nth-child(2) { left: calc(50% - 1406px); width: 1200px; }

@media only screen and (max-width: 1279px) { .cases__figure:nth-child(2) { left: -520px; width: 900px; } }

.cases__container { margin: 0 -16px; }

@media only screen and (max-width: 1279px) { .cases__container { margin: 0 -12px; } }

@media only screen and (max-width: 767px) { .cases__container { margin: 0 -32px; } }

.cases .owl-stage-outer { overflow: visible; }

.cases .owl-item { padding: 0 16px; opacity: 0; transition: opacity .25s; }

@media only screen and (max-width: 1279px) { .cases .owl-item { padding: 0 12px; } }

@media only screen and (max-width: 767px) { .cases .owl-item { padding: 0; } }

.cases .owl-item.active { opacity: 1; }

.cases .owl-nav { position: absolute; top: -290px; right: calc(50% - 550px); justify-content: stretch; }

@media only screen and (max-width: 1365px) { .cases .owl-nav { right: 50px; } }

@media only screen and (max-width: 1279px) { .cases .owl-nav { top: -210px; } }

.cases .owl-dots { padding: 0 32px; }

.cases_main .cases__figure:nth-child(2) { top: 335px; }

@media only screen and (max-width: 1279px) { .cases_main .cases__figure:nth-child(2) { top: 260px; } }

.cases_other { margin-bottom: 160px; }

@media only screen and (max-width: 1279px) { .cases_other { margin-bottom: 88px; } }

@media only screen and (max-width: 767px) { .cases_other { display: none; margin-bottom: 56px; padding-top: 0; } }

.cases_other .cases__figure:nth-child(2) { top: 160px; }

.review { position: relative; margin-bottom: 130px; padding-top: 128px; }

@media only screen and (max-width: 1279px) { .review { margin-bottom: 90px; padding-top: 96px; } }

@media only screen and (max-width: 767px) { .review { margin-bottom: 72px; padding-top: 72px; } }

.review__center { position: relative; z-index: 2; max-width: 933px; }

@media only screen and (max-width: 1279px) { .review__center { max-width: 730px; } }

.review__stage.stage { padding: 0; text-align: center; }

.review__stage.stage:before { display: none; }

.review__title { margin-bottom: 96px; text-align: center; }

@media only screen and (max-width: 1279px) { .review__title { margin-bottom: 48px; } }

@media only screen and (max-width: 767px) { .review__title { margin-bottom: 24px; } }

.review__container { margin: 0 -16px; }

.review__item { position: relative; padding: 64px 44px 82px 64px; border-radius: 24px; background: #fff; }

@media only screen and (max-width: 1279px) { .review__item { padding: 48px; } }

@media only screen and (max-width: 767px) { .review__item { padding: 40px 38px 60px; text-align: center; } }

.review__item:before, .review__item:after { content: ""; position: absolute; }

.review__item:before { top: 8px; left: 0; right: 0; bottom: -8px; z-index: -1; border-radius: 24px; background: rgba(255, 255, 255, 0.3); }

@media only screen and (max-width: 767px) { .review__item:before { display: block; background: rgba(255, 255, 255, 0.5); } }

.review__item:after { left: 32px; right: 32px; bottom: -55px; z-index: -2; height: 180px; border-radius: 24px; background: #DBDEE1; filter: blur(86.985px); visibility: hidden; opacity: 0; pointer-events: none; transition: all .25s; }

@media only screen and (max-width: 1279px) { .review__item:after { left: 23px; right: 23px; bottom: -48px; height: 96px; border-radius: 17px; filter: blur(108.731px); } }

@media only screen and (max-width: 767px) { .review__item:after { top: 58px; left: 20px; right: 20px; bottom: -24px; height: auto; } }

.review__item:hover:after { visibility: visible; opacity: 1; }

.review__text { margin-bottom: 50px; font-size: 28px; line-height: 1.42857; }

@media only screen and (max-width: 1279px) { .review__text { margin-bottom: 40px; font-size: 18px; line-height: 1.77778; } }

@media only screen and (max-width: 767px) { .review__text { margin-bottom: 35px; } }

.review__author { display: inline-flex; align-items: center; }

@media only screen and (max-width: 767px) { .review__author { display: block; } }

.review__ava { flex-shrink: 0; width: 64px; height: 64px; margin-right: 16px; font-size: 0; }

@media only screen and (max-width: 1279px) { .review__ava { width: 46px; height: 46px; margin-right: 12px; } }

@media only screen and (max-width: 767px) { .review__ava { margin: 0 auto 12px; } }

.review__ava .review__pic { width: 100%; min-height: 100%; border-radius: 50%; }

@media only screen and (max-width: 1279px) { .review__man { font-size: 18px; line-height: 1.44444; } }

.review__company { color: #6b6b6b; }

.review__preview { position: absolute; top: -78px; left: 50%; width: 730px; margin-left: -416px; opacity: .5; pointer-events: none; font-size: 0; }

@media only screen and (max-width: 1279px) { .review__preview { top: -46px; width: 550px; margin-left: -308px; } }

@media only screen and (max-width: 767px) { .review__preview { top: 463px; left: -137px; z-index: 3; width: 300px; margin: 0; } }

.review__preview:nth-child(2) { display: none; }

@media only screen and (max-width: 767px) { .review__preview:nth-child(2) { display: block; top: 328px; left: auto; right: -22px; width: 120px; } }

.review__preview .review__pic { width: 100%; }

.review .owl-stage-outer { position: relative; z-index: 2; overflow: visible; }

.review .owl-item { padding: 0 16px; opacity: .4; transition: all .25s; }

.review .owl-item.active { opacity: 1; }

@media only screen and (max-width: 1279px) { .review .owl-item.active .review__item:after { visibility: visible; opacity: 1; } }

.review .owl-nav { margin-top: 80px; }

@media only screen and (max-width: 1279px) { .review .owl-nav { margin-top: 68px; } }

.review .owl-dots { position: absolute; left: 0; right: 0; bottom: 24px; z-index: 3; justify-content: center; }

.field__wrap { position: relative; }

.field__input, .field__textarea, .field__select { width: 100%; background: rgba(244, 244, 244, 0.801191); border-radius: 12px; border: 2px solid rgba(244, 244, 244, 0.801191); font-family: 'Poppins', sans-serif; font-size: 18px; color: #111029; transition: all .25s; }

@media only screen and (max-width: 1279px) { .field__input, .field__textarea, .field__select { font-size: 16px; } }

.field__input:focus, .field__textarea:focus, .field__select:focus { border-color: #2B6BFF; background: #fff; }

.field__input::placeholder, .field__textarea::placeholder, .field__select::placeholder { color: #6b6b6b; }

.field__input:focus + .field__icon .icon, .field__textarea:focus + .field__icon .icon, .field__select:focus + .field__icon .icon { fill: #2B6BFF; }

.field__input, .field__select { height: 72px; padding: 0 72px 0 24px; }

@media only screen and (max-width: 1279px) { .field__input, .field__select { height: 56px; padding-right: 56px; } }

.field__textarea { height: 200px; resize: none; padding: 20px 72px 20px 24px; }

@media only screen and (max-width: 1279px) { .field__textarea { height: 110px; padding: 12px 56px 14px 24px; } }

.field__select { -webkit-appearance: none; -moz-appearance: none; appearance: none; }

.field__select::-ms-expand { display: none; }

.field__select:-moz-focusring { color: transparent; text-shadow: 0 0 0 #000; }

.field__icon { position: absolute; top: 0; right: 0; display: flex; justify-content: center; align-items: center; width: 72px; height: 72px; font-size: 0; pointer-events: none; }

@media only screen and (max-width: 1279px) { .field__icon { width: 56px; height: 56px; } }

.field__icon .icon { font-size: 17px; fill: #111029; transition: fill .25s; }

@media only screen and (max-width: 1279px) { .field__icon .icon { font-size: 14px; } }

.field_textarea { font-size: 0; }

.contacts { position: relative; margin-bottom: 182px; z-index: 3; }

@media only screen and (max-width: 1279px) { .contacts { margin-bottom: 112px; } }

@media only screen and (max-width: 767px) { .contacts { margin-bottom: 57px; } }

.contacts__container { position: relative; padding: 162px 100px 60px; background: #fff; border-radius: 48px; }

@media only screen and (max-width: 1279px) { .contacts__container { padding: 78px 73px 40px; border-radius: 34px; } }

@media only screen and (max-width: 767px) { .contacts__container { padding: 0; border-radius: 0; background: none; } }

.contacts__container:before, .contacts__container:after { content: ""; position: absolute; top: 50px; border-radius: 34px; }

@media only screen and (max-width: 767px) { .contacts__container:before, .contacts__container:after { display: none; } }

.contacts__container:after { left: 17px; right: 17px; bottom: -16px; z-index: -1; background: #203696; }

.contacts__container:before { left: 50px; right: 50px; bottom: -32px; z-index: -2; background: #1B1B87; }

.contacts__wrap { position: relative; z-index: 3; margin-bottom: 85px; }

@media only screen and (max-width: 767px) { .contacts__wrap { margin-bottom: 40px; } }

.contacts__title { margin-bottom: 40px; }

@media only screen and (max-width: 1279px) { .contacts__title { margin-bottom: 32px; } }

@media only screen and (max-width: 767px) { .contacts__title { margin: 0; } }

.contacts__info { max-width: 366px; color: #6b6b6b; }

@media only screen and (max-width: 1279px) { .contacts__info { max-width: 270px; } }

@media only screen and (max-width: 767px) { .contacts__info { display: none; } }

.contacts__row { position: relative; z-index: 3; display: flex; margin-bottom: 93px; }

@media only screen and (max-width: 1279px) { .contacts__row { margin-bottom: 46px; } }

@media only screen and (max-width: 1023px) { .contacts__row { display: block; } }

@media only screen and (max-width: 767px) { .contacts__row { margin-bottom: 40px; } }

.contacts__details { flex-shrink: 0; width: 340px; margin-right: auto; }

@media only screen and (max-width: 1023px) { .contacts__details { width: 100%; margin-bottom: 38px; } }

.contacts__line { display: flex; color: #111029; }

@media only screen and (max-width: 1279px) { .contacts__line { font-size: 16px; line-height: 1.75; } }

@media only screen and (max-width: 767px) { .contacts__line { font-weight: 600; } }

.contacts__line:not(:last-child) { margin-bottom: 40px; }

@media only screen and (max-width: 1279px) { .contacts__line:not(:last-child) { margin-bottom: 20px; } }

.contacts__icon { display: flex; justify-content: center; align-items: center; flex-shrink: 0; width: 24px; height: 30px; margin-right: 45px; font-size: 0; }

@media only screen and (max-width: 1279px) { .contacts__icon { margin-right: 24px; width: 17px; height: 28px; } }

.contacts__icon .icon { fill: #2B6BFF; }

.contacts__icon .icon-email { font-size: 20px; }

@media only screen and (max-width: 1279px) { .contacts__icon .icon-email { font-size: 14px; } }

.contacts__icon .icon-place { font-size: 23px; }

@media only screen and (max-width: 1279px) { .contacts__icon .icon-place { font-size: 17px; } }

.contacts__icon .icon-phone { font-size: 24px; }

@media only screen and (max-width: 1279px) { .contacts__icon .icon-phone { font-size: 18px; } }

.contacts__form { flex-shrink: 0; width: 468px; }

@media only screen and (max-width: 1279px) { .contacts__form { width: 345px; } }

@media only screen and (max-width: 1023px) { .contacts__form { width: 100%; } }

.contacts__feedback { height: 72px; padding: 20px; text-align: center; }

.contacts__feedback .success { color: #15b515; }

.contacts__feedback .fail { color: #b51515; }

.contacts__field:not(:last-child) { margin-bottom: 24px; }

@media only screen and (max-width: 1279px) { .contacts__field:not(:last-child) { margin-bottom: 16px; } }

@media only screen and (max-width: 767px) { .contacts__btn .btn { min-width: 100%; } }

.contacts__category { display: none; }

@media only screen and (max-width: 767px) { .contacts__category { display: block; margin-bottom: 32px; } }

.contacts__social { display: flex; align-items: center; margin: 0 -100px; }

@media only screen and (max-width: 1279px) { .contacts__social { margin: 0 -73px; } }

@media only screen and (max-width: 767px) { .contacts__social { flex-wrap: wrap; margin: -16px -8px 0; } }

.contacts__box { flex: 0 0 25%; width: 25%; }

@media only screen and (max-width: 767px) { .contacts__box { flex: 0 0 calc(50% - 16px); width: calc(50% - 16px); margin: 16px 8px 0; } }

.contacts__social .contacts__link { position: relative; display: flex; justify-content: center; align-items: center; height: 140px; padding: 0 10px; font-size: 16px; font-weight: 500; color: #9F9FA9; transition: color .25s; }

@media only screen and (max-width: 1279px) { .contacts__social .contacts__link { height: 100px; } }

@media only screen and (max-width: 767px) { .contacts__social .contacts__link { background: rgba(244, 244, 244, 0.8); border-radius: 17px; font-size: 14px; } }

.contacts__social .contacts__link .icon { position: relative; z-index: 3; margin-right: 14px; font-size: 16px; fill: #9F9FA9; transition: fill .25s; }

@media only screen and (max-width: 1279px) { .contacts__social .contacts__link .icon { font-size: 14px; } }

@media only screen and (max-width: 767px) { .contacts__social .contacts__link .icon { margin-right: 10px; fill: #111029; } }

.contacts__social .contacts__link:after { content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 2; border-radius: 24px; }

.contacts__social .contacts__link:before { content: ""; position: absolute; bottom: -50px; left: 0; right: 0; height: 120px; border-radius: 32px; background: #DBDEE1; filter: blur(86.985px); visibility: hidden; opacity: 0; pointer-events: none; transition: all .25s; }

@media only screen and (max-width: 1365px) { .contacts__social .contacts__link:before { bottom: -20px; } }

.contacts__social .contacts__link:hover { background: #fff; color: #111029; }

.contacts__social .contacts__link:hover:after { background: #fff; }

.contacts__social .contacts__link:hover:before { visibility: visible; opacity: 1; }

.contacts__social .contacts__link:hover .icon { fill: #111029; }

.contacts__link .contacts__text { position: relative; z-index: 3; }

.contacts .figures { top: 109px; right: calc(50% - 720px); }

@media only screen and (max-width: 1365px) { .contacts .figures { right: calc(50% - 670px); } }

@media only screen and (max-width: 1279px) { .contacts .figures { top: 80px; right: calc(50% - 530px); } }

@media only screen and (max-width: 1023px) { .contacts .figures { top: 130px; right: calc(50% - 450px); } }

@media only screen and (max-width: 767px) { .contacts .figures { top: 90px; right: -90px; width: 320px; } }

.figures { position: absolute; width: 1120px; pointer-events: none; font-size: 0; }

@media only screen and (max-width: 1279px) { .figures { width: 800px; } }

@media only screen and (max-width: 1023px) { .figures { width: 700px; } }

.figures__preview:nth-child(2) { top: -16.2%; left: -1.8%; width: 87%; }

.figures__preview:nth-child(3) { top: -17%; right: 16.2%; width: 19%; }

.figures__preview:nth-child(4) { top: 12%; right: 11.7%; z-index: 2; width: 26.2%; }

.figures__preview:nth-child(5) { left: 35.2%; bottom: 23%; z-index: 3; width: 27.5%; }

.figures__preview:nth-child(6) { right: 0; bottom: 10%; z-index: 3; width: 34%; }

.figures__preview:not(:first-child) { position: absolute; }

.figures__preview .figures__pic { width: 100%; }

.footer { position: relative; z-index: 2; color: #fff; }

@media only screen and (max-width: 767px) { .footer { position: relative; padding: 56px 0 60px; background: #00113B url("../img/bg-footer.png") no-repeat 100% 100%/100% auto; }
  .footer:before, .footer:after { content: ""; position: absolute; left: 50%; z-index: -1; transform: translateX(-50%); border-radius: 50%; border: 1px solid rgba(255, 255, 255, 0.1); pointer-events: none; }
  .footer:before { top: 90px; width: 600px; height: 600px; }
  .footer:after { top: 201px; width: 376px; height: 376px; } }

.footer__center { position: relative; z-index: 3; }

.footer__body { display: flex; align-items: flex-start; margin-bottom: 113px; padding-right: 74px; }

@media only screen and (max-width: 1365px) { .footer__body { padding: 0; } }

@media only screen and (max-width: 1279px) { .footer__body { margin-bottom: 88px; } }

@media only screen and (max-width: 767px) { .footer__body { display: block; margin-bottom: 52px; } }

.footer__logo { display: inline-block; font-size: 0; width: 50px; }

.footer__logo img { width: 90px; margin-bottom: 16px; }

@media only screen and (max-width: 1279px) { .footer__logo .footer__pic { width: 50px; } }

@media only screen and (max-width: 1023px) { .footer__logo .footer__pic { width: 40px; } }

@media only screen and (max-width: 767px) { .footer__logo .footer__pic { width: 70px; } }

.footer__wrap { max-width: 900px; margin: -22px auto 0; }

@media only screen and (max-width: 1279px) { .footer__wrap { max-width: 490px; margin-top: -10px; } }

@media only screen and (max-width: 1023px) { .footer__wrap { max-width: 430px; } }

@media only screen and (max-width: 767px) { .footer__wrap { max-width: 100%; margin: 0 0 42px; } }

.footer__title { margin-bottom: 48px; }

@media only screen and (max-width: 1279px) { .footer__title { margin-bottom: 44px; font-size: 42px; line-height: 1.33333; } }

@media only screen and (max-width: 1023px) { .footer__title { font-size: 36px; } }

@media only screen and (max-width: 767px) { .footer__title { margin-bottom: 32px; font-size: 42px; line-height: 1.33333; } }

.footer__info { color: #fff; }

@media only screen and (max-width: 1279px) { .footer__info { font-size: 20px; line-height: 1.4; } }

@media only screen and (max-width: 767px) { .footer__info { font-size: 16px; line-height: 1.625; } }

.footer__menu { display: flex; flex-direction: column; align-items: flex-start; }

@media only screen and (max-width: 767px) { .footer__menu { display: block; column-count: 2; padding-right: 100px; } }

.footer__link { line-height: 1.44444; font-weight: 500; color: #fff; transition: opacity .25s; }

@media only screen and (max-width: 1279px) { .footer__link { font-size: 14px; } }

@media only screen and (max-width: 767px) { .footer__link { display: block; } }

.footer__link:hover { opacity: .3; }

.footer__link:not(:last-child) { margin-bottom: 23px; }

@media only screen and (max-width: 1279px) { .footer__link:not(:last-child) { margin-bottom: 20px; } }

.footer__bottom { display: flex; align-items: center; margin: 0 -67px; padding: 45px 67px 58px; border-top: 1px solid rgba(216, 216, 216, 0.2); font-size: 16px; line-height: 1.5; font-weight: 500; }

@media only screen and (max-width: 1279px) { .footer__bottom { padding: 32px 0 42px; font-size: 14px; } }

@media only screen and (max-width: 1365px) { .footer__bottom { margin: 0; padding: 45px 0 58px; } }

@media only screen and (max-width: 767px) { .footer__bottom { padding: 0; border: none; } }

.footer__copyright { margin-right: auto; }

.footer__scroll { color: #fff; transition: opacity .25s; }

@media only screen and (max-width: 767px) { .footer__scroll { display: none; } }

.footer__scroll:hover { opacity: .85; }

.footer__preview { position: absolute; font-size: 0; pointer-events: none; }

.footer__preview:first-child { top: -155px; left: calc(50% - 536px); width: 330px; }

@media only screen and (max-width: 1279px) { .footer__preview:first-child { top: -105px; left: calc(50% - 355px); width: 250px; } }

@media only screen and (max-width: 767px) { .footer__preview:first-child { display: none; } }

.footer__preview:nth-child(2) { top: 233px; right: calc(50% - 237px); width: 150px; }

@media only screen and (max-width: 1279px) { .footer__preview:nth-child(2) { top: 174px; right: calc(50% - 163px); width: 83px; } }

@media only screen and (max-width: 767px) { .footer__preview:nth-child(2) { display: none; } }

@media only screen and (max-width: 639px) { .footer__preview:nth-child(2) { top: 300px; right: 50px; width: 150px; } }

.footer__preview .footer__pic { width: 100%; }

.content { color: #6b6b6b; }

.content p:not(:last-child) { margin-bottom: 32px; }

@media only screen and (max-width: 1279px) { .content p:not(:last-child) { margin-bottom: 24px; } }

.product__head { padding: 192px 0 128px; }

@media only screen and (max-width: 1279px) { .product__head { padding: 160px 0 88px; } }

@media only screen and (max-width: 767px) { .product__head { padding: 118px 0 48px; } }

.product__head .product__center { display: flex; max-width: 1132px; }

@media only screen and (max-width: 1279px) { .product__head .product__center { max-width: 846px; } }

@media only screen and (max-width: 767px) { .product__head .product__center { display: block; } }

.product__wrap { flex-grow: 1; padding-right: 100px; }

@media only screen and (max-width: 767px) { .product__wrap { padding: 0; } }

.product__box { flex-shrink: 0; margin-right: -18px; padding-top: 76px; }

@media only screen and (max-width: 1279px) { .product__box { padding-top: 56px; } }

@media only screen and (max-width: 767px) { .product__box { padding: 0; } }

@media only screen and (max-width: 767px) { .product__box_show { display: none; } }

.product__box_hide { display: none; }

@media only screen and (max-width: 767px) { .product__box_hide { display: block; margin-bottom: 48px; } }

.product__title { margin-bottom: 46px; }

@media only screen and (max-width: 1279px) { .product__title { margin-bottom: 32px; } }

@media only screen and (max-width: 767px) { .product__title { margin-bottom: 16px; } }

.product__info { max-width: 360px; color: #6b6b6b; }

@media only screen and (max-width: 1023px) { .product__info { font-size: 16px; line-height: 1.75; } }

@media only screen and (max-width: 767px) { .product__info { max-width: 230px; font-weight: 400; } }

.product__category { margin-bottom: 40px; font-weight: 600; }

@media only screen and (max-width: 1279px) { .product__category { margin-bottom: 24px; } }

.product__list li { color: #6b6b6b; }

.product__list li:not(:last-child) { margin-bottom: 8px; }

@media only screen and (max-width: 1279px) { .product__list li:not(:last-child) { margin-bottom: 4px; } }

.product__bg { height: 500px; background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; background-attachment: fixed; }

@media only screen and (max-width: 1439px) { .product__bg { height: 500px; } }

@media only screen and (max-width: 767px) { .product__bg { height: 768px; } }

@media only screen and (max-width: 767px) { .product__bg { height: 320px; } }

.product__bg2 { height: 2160px; background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; background-attachment: fixed; height: 2160px; height: 768px; height: 320px; }

.product__preview { font-size: 0; }

.product__preview .product__pic { width: 100%; }

.product__body { padding: 114px 0 128px; }

@media only screen and (max-width: 1279px) { .product__body { padding: 88px 0; } }

@media only screen and (max-width: 767px) { .product__body { padding: 48px 0 112px; } }

.product__body2 { padding: 0px 0 128px; }

@media only screen and (max-width: 1279px) { .product__body2 { padding: 88px 0; } }

@media only screen and (max-width: 767px) { .product__body2 { padding: 48px 0 112px; } }

.product__row { display: flex; align-items: flex-start; max-width: 970px; margin: 0 auto 112px; }

@media only screen and (max-width: 1279px) { .product__row { max-width: 713px; margin-bottom: 88px; } }

@media only screen and (max-width: 767px) { .product__row { display: block; max-width: 100%; margin-bottom: 48px; } }

.product__overview { flex-shrink: 0; width: 298px; padding-right: 30px; }

@media only screen and (max-width: 1279px) { .product__overview { position: relative; width: 220px; padding-bottom: 16px; }
  .product__overview:before { content: ""; position: absolute; left: 0; bottom: 0; width: 108px; height: 2px; background: #2B6BFF; } }

@media only screen and (max-width: 767px) { .product__overview { width: 100%; margin-bottom: 32px; padding-bottom: 32px; }
  .product__overview:before { width: 56px; } }

.product__body .product__preview { margin-bottom: 130px; }

@media only screen and (max-width: 1279px) { .product__body .product__preview { margin-bottom: 88px; } }

@media only screen and (max-width: 767px) { .product__body .product__preview { margin: 0 -32px 80px; } }

.product__body .product__preview .product__pic { border-radius: 12px; }

@media only screen and (max-width: 767px) { .product__body .product__preview .product__pic { border-radius: 0; } }

.product__review { display: flex; align-items: flex-start; max-width: 970px; margin: 0 auto; }

@media only screen and (max-width: 1279px) { .product__review { max-width: 713px; } }

@media only screen and (max-width: 767px) { .product__review { flex-direction: column-reverse; align-items: stretch; max-width: 100%; padding: 0 40px; text-align: center; } }

.product__author { display: flex; align-items: center; flex-shrink: 0; width: 298px; padding-right: 30px; }

@media only screen and (max-width: 1279px) { .product__author { width: 220px; } }

@media only screen and (max-width: 767px) { .product__author { display: block; width: 100%; padding: 0; } }

.product__ava { flex-shrink: 0; width: 64px; height: 64px; margin-right: 16px; font-size: 0; }

@media only screen and (max-width: 1279px) { .product__ava { width: 46px; height: 46px; } }

@media only screen and (max-width: 767px) { .product__ava { margin: 0 auto 12px; } }

.product__ava .product__pic { width: 100%; min-height: 100%; }

.product__rounded { border-radius: 15px; }

.product__company { color: #6b6b6b; }

.product__review .product__text { margin-top: -13px; font-size: 25px; line-height: 1.33333; font-weight: 200; letter-spacing: -.1px; }

@media only screen and (max-width: 1279px) { .product__review .product__text { font-size: 32px; line-height: 1.5; letter-spacing: -.15px; } }

@media only screen and (max-width: 767px) { .product__review .product__text { margin: 0 0 35px; font-size: 18px; line-height: 1.77778; letter-spacing: -.6px; font-weight: 400; } }

.video-block { position: relative; height: 100%; overflow: hidden; }

@media only screen and (max-width: 767px) { .video-block video { min-width: 100%; position: absolute; top: 50%; transform: translate(0, -50%); left: 0; min-height: 100%; } }

.discover { position: relative; padding: 203px 0; background: #1B1B87; color: #fff; }

@media only screen and (max-width: 1279px) { .discover { padding: 144px 0 147px; } }

@media only screen and (max-width: 1023px) { .discover { padding: 100px 0; } }

@media only screen and (max-width: 767px) { .discover { padding: 48px 0; } }

.discover:before { content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url("../img/bg-discover.jpg") no-repeat 50% 50%/cover; opacity: .044; background-attachment: fixed; }

.discover__center { position: relative; z-index: 3; max-width: 1134px; }

.discover__title { margin-bottom: 96px; }

@media only screen and (max-width: 1279px) { .discover__title { margin-bottom: 40px; } }

.discover__row { display: flex; }

@media only screen and (max-width: 1023px) { .discover__row { display: block; } }

.discover__item:not(:last-child) { margin-right: 132px; }

@media only screen and (max-width: 1279px) { .discover__item:not(:last-child) { margin-right: 50px; } }

@media only screen and (max-width: 1023px) { .discover__item:not(:last-child) { margin: 0 0 48px; } }

.discover__category { margin-bottom: 34px; }

@media only screen and (max-width: 1279px) { .discover__category { margin-bottom: 24px; } }

.discover__list li:not(:last-child) { margin-bottom: 12px; }

@media only screen and (max-width: 1279px) { .discover__list li:not(:last-child) { margin-bottom: 4px; } }

.discover__circles { position: absolute; top: 0; left: 0; width: 100%; height: 100%; overflow: hidden; }

.discover__circles:before, .discover__circles:after { content: ""; position: absolute; top: 50%; transform: translateY(-50%); border-radius: 50%; border: 1px solid rgba(255, 255, 255, 0.15); pointer-events: none; }

.discover__circles:before { right: calc(50% - 900px); width: 902px; height: 902px; }

@media only screen and (max-width: 767px) { .discover__circles:before { right: calc(50% - 950px); } }

.discover__circles:after { right: calc(50% - 1200px); width: 1470px; height: 1470px; }

@media only screen and (max-width: 767px) { .discover__circles:after { display: none; } }

.discover .figures { right: calc(50% - 924px); bottom: 45px; width: 1000px; }

@media only screen and (max-width: 1279px) { .discover .figures { right: calc(50% - 663px); bottom: -30px; width: 800px; } }

@media only screen and (max-width: 1023px) { .discover .figures { right: calc(50% - 478px); bottom: 36px; } }

@media only screen and (max-width: 767px) { .discover .figures { display: none; } }

.desc { padding: 112px 0 128px; }

@media only screen and (max-width: 1279px) { .desc { padding: 88px 0; } }

@media only screen and (max-width: 767px) { .desc { padding: 48px 0; } }

.desc__head { display: flex; align-items: flex-start; max-width: 970px; margin: 0 auto 112px; }

@media only screen and (max-width: 1279px) { .desc__head { max-width: 713px; margin-bottom: 88px; } }

@media only screen and (max-width: 767px) { .desc__head { display: block; max-width: 100%; margin-bottom: 48px; } }

.desc__title { flex-shrink: 0; width: 298px; padding-right: 20px; }

@media only screen and (max-width: 1279px) { .desc__title { position: relative; width: 220px; padding-bottom: 16px; }
  .desc__title:before { content: ""; position: absolute; left: 0; bottom: 0; width: 101px; height: 2px; background: #2B6BFF; } }

@media only screen and (max-width: 767px) { .desc__title { width: 100%; margin-bottom: 32px; padding-bottom: 32px; }
  .desc__title:before { width: 56px; } }

.desc__list { display: flex; flex-wrap: wrap; margin: -32px -16px 0; }

@media only screen and (max-width: 1279px) { .desc__list { margin: -24px -12px 0; } }

@media only screen and (max-width: 767px) { .desc__list { display: block; margin: 0; } }

.desc__preview { display: block; position: relative; flex: 0 0 calc(50% - 32px); width: calc(50% - 32px); margin: 32px 16px 0; font-size: 0; }

@media only screen and (max-width: 1279px) { .desc__preview { flex: 0 0 calc(50% - 24px); width: calc(50% - 24px); margin: 24px 12px 0; } }

@media only screen and (max-width: 767px) { .desc__preview { width: 100%; margin: 0; }
  .desc__preview:not(:last-child) { margin-bottom: 16px; } }

.desc__preview > .desc__pic { width: 100%; border-radius: 16px; }

@media only screen and (max-width: 767px) { .desc__preview > .desc__pic { border-radius: 12px; } }

.desc__zoom { position: absolute; top: 40px; right: 40px; display: flex; justify-content: center; align-items: center; width: 72px; height: 72px; border-radius: 50%; box-shadow: 0 5px 15px rgba(0, 17, 59, 0.1); background: #fff; font-size: 0; opacity: 0; transition: opacity .25s; }

@media only screen and (max-width: 1023px) { .desc__zoom { display: none; } }

.desc__zoom .icon { font-size: 16px; fill: #111029; }

.desc__preview:hover .desc__zoom { opacity: 1; }

.desc__btns { display: none; }

@media only screen and (max-width: 767px) { .desc__btns { display: flex; margin: 32px -8px 0; } }

.desc__btns .desc__btn { flex: 0 0 calc(50% - 16px); min-width: calc(50% - 16px); margin: 0 8px; }

.fancybox-button--zoom { display: none !important; }

[data-aos="animation-scale"] { transform: translateY(30px) scale(0.5); opacity: 0; transition-property: transform, opacity; }

[data-aos="animation-scale"].aos-animate { transform: translateY(0) scale(1); opacity: 1; }

[data-aos="animation-scale-x-left"] { transform: translateX(-50px) scaleX(1.1); opacity: 0; transition-property: transform, opacity; }

[data-aos="animation-scale-x-left"].aos-animate { transform: translateX(0) scaleX(1); opacity: 1; }

[data-aos="animation-scale-x-right"] { transform: translateX(50px) scaleX(1.1); opacity: 0; transition-property: transform, opacity; }

[data-aos="animation-scale-x-right"].aos-animate { transform: translateX(0) scaleX(1); opacity: 1; }

[data-aos="animation-scale-y"] { transform: translateY(40px) scaleY(1.4); opacity: 0; transition-property: transform, opacity; }

[data-aos="animation-scale-y"].aos-animate { transform: translateY(0) scaleY(1); opacity: 1; }

[data-aos="animation-translate-y"] { transform: translateY(40px); opacity: 0; transition-property: transform, opacity; }

[data-aos="animation-translate-y"].aos-animate { transform: translateY(0); opacity: 1; }

[data-aos="transform"] { transform: rotateX(90deg) skewY(-5deg); opacity: 0; transition-property: transform, opacity; }

[data-aos="transform"].aos-animate { transform: translate(0) rotateX(0deg) skewY(0deg); opacity: 1; }

.magnet { transition: transform .3s ease; }
